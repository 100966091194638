/**
 * Signin Firebase
 */

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    email_address: yup
      .string()
      .matches(constant.emailrgx, "Email is required")
      .required("Email is required")
      .trim(),
    password: yup.string().min(6).required("Password is required").trim(),
  })
  .required();

const Registrationpage = (props) => {
  /**
   * On User Login
   */
  const [eye, seteye] = useState(true);
  const [emailerror, setEmailError] = useState("");
  const [nameerror, setNameError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [formgroup, setFormGroup] = useState("");
  const [inputValues, setInputValues] = useState({
    employee_name: "",
    mobile_num: "",
    email_address: "",
    password: "",
  });

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("signup data", data, props);

    props.api
      .apiCreateEmployeeAdmin({
        data,
      })
      .then((res) => {
        console.log("signup res", res);

        // props.setstore("auth", res.auth);
        // props.setstore("user", res.user);
        // clearErrors("password");
      })
      .catch(() => {
        setError("password", {
          message: "Signup failed",
        });
      });
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  const { loading } = props;
  return (
    <>
      <Helmet>
        <title>Register - Starpowerz Digital Technologies Pvt. Ltd.</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="account-content">
        {/* <Link to="/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</Link> */}
        <div className="container">
          <div className="account-box">
            <div className="account-wrapper">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="/open/login">
                  <img src={imagepaths.Applogo} alt="" />
                  <div className="tagline">Makerspace for Schools</div>
                </Link>
              </div>
              <h3 className="account-title">Sign Up</h3>
              <p className="account-subtitle">Access to explore more</p>
              {/* Account Form */}
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label>Name</label>
                    <Controller
                      name="employee_name"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.email ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter your name here"
                        />
                      )}
                    />
                    <small>{errors?.employee_name?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    <Controller
                      name="email_address"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.email ? "error-input" : ""
                          }`}
                          type="email"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                    />

                    <small>{errors?.email_address?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Mobile No.</label>
                    <Controller
                      name="mobile_num"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors?.mobile_num ? "error-input" : ""
                          }`}
                          type="phone"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                        />
                      )}
                    />

                    <small>{errors?.mobile_num?.message}</small>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={eye ? "password" : "text"}
                            className={`form-control  ${
                              errors?.password ? "error-input" : ""
                            }`}
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                          <span
                            onClick={onEyeClick}
                            className={`fa toggle-password" ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                      )}
                    />

                    <small>{errors?.password?.message}</small>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Register
                    </button>
                  </div>
                </form>
                <div className="account-footer">
                  <p className="text-black text-end">
                    Not received OTP?
                    <Link to=""> Resend</Link>
                  </p>
                </div>
              </div>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(Registrationpage);
