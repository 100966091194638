export const loginService = {
  apiGetOrganisationConfig() {
    let data = {
      name: "",
      shortname: "",
      tagline: "",
      logo: "self",
      colors: {
        defaultbg: "white",
        defaultText: "black",
        primary: "#1676FF",
        primaryText: "white",
        secoundary: "#692E20",
        secoundaryText: "white",
      },
    };
    return this.mokeapi(() => data, "", 2000);
  },
  apiLoginMoke() {
    let data = {
      user: {
        name: "Admin",
        role: "employee",
      },
      auth: (10).uniqueId().toString().encode(),
    };
    return this.mokeapi(() => data, <div>Logging in...</div>, 2000);
  },

  apiLogin(data) {
    return this.request(this.api().login, data)
      .exec()
      .log()
      // .mapresults((response) => {
      //   return new Promise((resolve, reject) => {
      //     this.request(
      //       this.api().getProfile,
      //       {},
      //       {
      //         authorization: "Bearer " + response.data.access_token,
      //       }
      //     )
      //       .exec()
      //       .get()
      //       .then((userresponse) => {
      //         console.log("userresponse", userresponse);

      //         let adminArray = userresponse.data[0].records;

      //         const currentAdmin = adminArray.filter(
      //           (admin) => admin.email == data.email
      //         );
      //         localStorage.setItem("currentAdminRole", currentAdmin[0].role);
      //         localStorage.setItem(
      //           "userresponse",
      //           JSON.stringify(userresponse)
      //         );

      //         this.request(this.api().getUser, {}, {
      //           authorization: "Bearer " + response.data.access_token,
      //         }).exec()
      //           .get()
      //           .then((user) => {
      //             localStorage.setItem('school_id', user.data.school_id)
      //           })

      //         let retdata = {
      //           auth: response.data.access_token,
      //           logged: {
      //             ...Object.except(data, ["password"]),
      //             ...response.data,
      //           },
      //           user: {
      //             ...userresponse.data,
      //             refresh: response.data.refresh_token,
      //           },
      //         };

      //         setTimeout(() => {
      //           this.apiRefreshToken();
      //         }, retdata.logged.accessExpiresIn);
      //         resolve(retdata);
      //         return retdata;
      //       });
      //   });
      // })
      .mapresults(response => {
        console.log({ response });
        let retdata = {
          auth: response.data.access_token,
          logged: {
            ...Object.except(data, ["password"]),
            ...response.data,
          },
          user: {
            refresh: response.data.refresh_token,
          },
        };

        console.log({ retdata });
        // setTimeout(() => {
        //   this.apiRefreshToken(retdata.user.refresh);
        // }, retdata.logged.accessExpiresIn)
        return retdata;
      })
      .get();
  },

  apiForgetPassword(data) {
    console.log("apiForgetPassword", data);
    return this.request(this.api().forgetpassword)
      .urltransform({
        email: data.email,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiOTPLogin(data) {
    console.log("apiOTPLogin", data);
    return this.request(this.api().otpLogin)
      .urltransform({
        mobile_num: data.mobile,
      })
      .exec()
      .log()
      .mapresults((res) => {
        return res.data;
      })
      .get();
  },

  apiOTPLoginVerify(data) {
    console.log({ apiOTPLoginVerify: data });

    return this.request(this.api().otpLoginVerify, data)
      .exec()
      .log()
      .mapresults((response) => ({
        auth: response.data.access_token,
        logged: {
          ...Object.except(data, ["otp", "message_id"]),
          ...response.data,
        },
        user: {
          ...data.user,
          refresh: response.data.refresh_token,
        },
      }))
      .get();
  },

  apiRefreshToken(refresh_token) {
    console.log({ apiRefreshToken: refresh_token });
    let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
    refreshTokenAttemp = refreshTokenAttemp || 1;
    localStorage.setItem("refreshTokenAttemp", +refreshTokenAttemp + 1);
    return this.request(this.api().refreshToken, {}, { refresh: refresh_token, })
      // .headers({
      //   refresh: app.props.store.user.refresh,
      // })
      .exec()
      .log()
      .mapresults((response) => {
        console.log({ apiRefreshToken: response });
        let { logged = {} } = app.props.store;
        app.props.setstore("auth", response.data.access_token);
        app.props.setstore("logged", { ...logged, ...response.data });
        app.props.setstore("user.refresh", response.data.refresh_token);
        localStorage.setItem("refreshTokenAttemp", 0);
      })
      .get();
  },
};

export default loginService;
