import leadsapi from "./devapis/leadsapi";

export const devapis = {
    ...leadsapi,

    heartbeat: {
        url: "/heartbeat",
        method: "get"
    },
    login: {
        url: "/auth/login",
        method: "post"
    },
    refreshToken: {
        url: "/auth/refresh",
        method: "post"
    },
    forgetpassword: {
        url: "/auth/forget",
        method: "post"
    },
    changepassword: {
        url: "/auth/password",
        method: "put"
    },
    otpLogin: {
        url: "/auth/otp/{mobile_num}",
        method: "get"
    },
    otpLoginVerify: {
        url: "/auth/verify",
        method: "post"
    },
    downloadFile: {
        url: `/download-documents`,
        method: "post"
    },
    // PROFILES
    createProfile: {
        url: "/profile",
        method: "post"
    },
    getProfile: {
        url: "/profile",
        method: "get"
    },
    getUser: {
        url: "/profile/me",
        method: "get"
    },
    getProfileById: {
        url: "/profile/{profile_id}",
        method: "get"
    },
    updateProfileById: {
        url: "/profile/{profile_id}",
        method: "put"
    },
    deleteProfile: {
        url: "/profile/{profile_id}",
        method: "delete"
    },
    // LEADS API's
    getLeads: {
        url: "/lead",
        method: "get"
    },
    addLead: {
        url: "/lead",
        method: "post"
    },
    updateLead: {
        url: "/lead/{email}",
        method: "put"
    },
    deleteLead: {
        url: "/lead/{email}",
        method: "delete"
    },
    // USER API's
    getUsers: {
        url: "/user",
        method: "get"
    },
}

export default devapis;