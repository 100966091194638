export default {
    LEADS_STATUS: [
        { key: "OPEN", label: "Open" },
        { key: "CONFIRMED", label: "Confirmed" },
        { key: "NOT_INTERESTED", label: "Not Interested" },
        { key: "INTERESTED", label: "Interested" },
        { key: "CONTACTED", label: "Contacted" },
        { key: "CALL_NOT_ANSWERED", label: "Call Not Answered" },
        { key: "CALL_BACK", label: "Call Back" },
    ],
    LEADS_STATUS_MAP: {
        "OPEN": "Open",
        "CONFIRMED": "Confirmed",
        "NOT_INTERESTED": "Not Interested",
        "INTERESTED": "Interested",
        "CONTACTED": "Contacted",
        "CALL_NOT_ANSWERED": "Call Not Answered",
        "CALL_BACK": "Call Back"
    }
}