import React, { Component } from "react";
export class LeadsSearch extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      fields: [
        {
          name: "cname",
          label: "Name",
          type: "text",
          fieldClass: "col-12 col-md-2 col-lg-2 col-xl-2",
        },
        {
          name: "fname",
          label: "Fathers Name",
          type: "text",
          fieldClass: "col-12 col-md-3 col-lg-3 col-xl-2",
        },
        {
          name: "mobile",
          label: "Mobile Number",
          type: "number",
          fieldClass: "col-12 col-md-3 col-lg-3 col-xl-2",
        },
        {
          name: "city",
          label: "Location",
          type: "text",
          fieldClass: "col-12 col-md-2 col-lg-2 col-xl-2",
        },
      ],
    };
  }

  onSearch() {
    let data = this.refs.form.form.current.getData();
    // console.log("onSearch formData", data);
    data = Object.filter(data, (val) => val);
    // console.log("data on search", data);
    this.props.onSearch && this.props.onSearch(data);
  }

  clearTextData() {
    const { fields } = this.state;
    let updatedData = fields.map((item) => ({
      ...item,
      value: "",
    }));
    this.setState({ fields: [...updatedData] });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fields[0].value != prevState.fields[0].value) {
      let clearValues = this.state.fields.map((item) => {
        delete item.value;
        return item;
      });
      this.setState({ fields: clearValues });
    }
  }

  // updateFields(fields) {
  //   fields.find((o) => o.name === "project_id").options =
  //     this.state.projectOptions.options("project_id", "project_name");
  //   return fields;
  // }

  render() {
    let {} = this.state;
    let { fields } = this.state;
    // fields = this.updateFields(this.fields);

    return (
      <AppForm className="row g-3 filter-row mx-auto px-2 py-4" ref="form">
        {this.props.hideStatus
          ? fields
              .filter((v) => v.name !== "status")
              .map((field, i) => (
                <div className={field.fieldClass} key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))
          : fields.map((field, i) => (
              <div className={field.fieldClass} key={i}>
                <AppInputFocus {...field}></AppInputFocus>
              </div>
            ))}
        <div className="col-12 col-md-2 col-lg-2">
          <button
            type="submit"
            className="btn btn-sm filter_search_btn d-block d-md-none w-100"
            onClick={(...args) => this.onSearch(...args)}
          >
            Search
          </button>
          <button
            type="submit"
            className="btn btn-sm filter_search_btn d-none d-md-block"
            onClick={(...args) => this.onSearch(...args)}
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
        {!!this.props.data.length && (
          <div className="col-12 pt-3 pt-md-0">
            <span
              className="clear-all text--primary"
              onClick={() => this.clearTextData()}
            >
              Clear All
            </span>
          </div>
        )}
      </AppForm>
    );
  }
}

export default connect(LeadsSearch);
