export const dashboardService = {

    apiDashboardDataMoke() {
        let data = [
            {
                formName: "Total Schools",
                count: 10,
                icon: "las la-lightbulb",
            },
            {
                formName: "Total Classes",
                count: 35,
                icon: "las la-user-plus",
            },
            {
                formName: "Total Contents",
                count: 150,
                icon: "la la-users",
            },
            {
                formName: "Total Sessions",
                count: 38,
                icon: "las la-laptop",
            },
        ]
        return this.mokeapi(() => (data), "Getting all data please wait...", 1000);

    },
}

export default dashboardService;