import leadsenums from "./enums/leads.enum"
export default {
    ...leadsenums,
    FILEIDPAT: "^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$",
    MANDATORY_OBJS: {
        "name": {
            name: "name",
            field_name: "Name",
            field_type: "text",
            field_constraint: "mandatory,character",
            possible_values: []
        },
        "email": {
            name: "email",
            field_name: "Email",
            field_type: "text",
            field_constraint: "mandatory,email",
            possible_values: []
        },
        "mobile_no": {
            name: "mobile_no",
            field_name: "Mobile No",
            field_type: "text",
            field_constraint: "mandatory,phonenumber",
            possible_values: []
        }
    },
    MANDATORY_FIELDS: ["name", "email", "mobile_no"],
    APPLICANT_ROLES: [
        { key: "digital-sakhi", label: "Digital Sakhi" },
        { key: "manager", label: "Manager" },
        { key: "cluster-co-ordinator", label: "Cluster Co-Ordinator" },
        { key: "woman-entrepreneur", label: "Woman Entrepreneur" },
        { key: "others", label: "Others" },
    ],

    APPLICANT_LANGUAGE_LIST: [
        { key: "ta", label: "Tamil" },
        { key: "te", label: "Telugu" },
        { key: "kn", label: "Kannada" },
        { key: "hi", label: "Hindi" },
        { key: "en", label: "English" }
    ],
    FIELD_TYPES: [
        { key: "text", label: "Free Text" },
        { key: "textarea", label: "Free Text Area" },
        { key: "select", label: "Dropdown" },
        { key: "checkbox", label: "Multi Select : Check Box" },
        { key: "radio", label: "Single Select : Radio Button" },
        { key: "file", label: "Upload" },
        { key: "date", label: "Calendar" }
    ],
    QUESTION_FIELD_TYPES: [
        { key: "text", label: "Free Text" },
        { key: "checkbox", label: "Multi Select : Check Box" },
        { key: "radio", label: "Single Select : Radio Button" },
        { key: "file", label: "Upload" },
        { key: "questionfile", label: "Upload Questions", inkey: "file" },
    ],
    FIELD_CONSTRAINTS: [
        { key: "numeric", label: "Numeric" },
        { key: "character", label: "Character" },
        { key: "phonenumber", label: "Phone Number" },
        { key: "email", label: "Email" },
        { key: "future_dates_only", label: "Future Dates Only" },
        { key: "past_dates_only", label: "Past Dates Only" },
        { key: "mandatory", label: "Mandatory" }
    ],
    CONSTRAINTS_MAP: {
        numeric: "^(0|[1-9][0-9]+)$",
        // character: "^[A-Za-z ]+$",
        character: "",
        email: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
        phonenumber: "^[0-9]{10}$",
        mandatory: "^(.+)$",
        future_dates_only: (date) => (date.getTime() >= new Date().toDate().date().getTime()),
        past_dates_only: (date) => (date.getTime() <= new Date().toDate().date().getTime())
    },
    APPLICANT_STATUS: [
        { key: "open", label: "Open" },
        { key: "selected", label: "Selected" },
        { key: "rejected", label: "Rejected" },
    ],
}