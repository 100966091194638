import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class ResetPassword extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Reset Password - Starpowerz Digital Technologies Pvt. Ltd.</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="account-content">
          <div className="container">
            <div className="account-box">
              <div className="account-wrapper">
                <div className="account-logo pt-4">
                  <Link to={"/open/login"}>
                    <img src={imagepaths.Applogo} alt="" />
                    <div className="tagline">Makerspace for Schools</div>
                  </Link>
                </div>
                <h3 className="account-title">Reset Password?</h3>

                {/* Account Form */}
                <form>
                  <div className="form-group">
                    <label>New Password</label>
                    <input className="form-control" type="password" />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input className="form-control" type="password" />
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Change Password
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Remember your password?{" "}
                      <Link to="/open/login">Login</Link>
                    </p>
                  </div>
                </form>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(ResetPassword);
