import devapis from "./devapis";
import stagingapis from "./stagingapis";
import prodapis from "./prodapis";

export const config = {
    "dev": {
        "base": "http://localhost:3000,http://localhost:3001,https://strdapi.impetusaisolutions.com",
        "api_base": "https://strdapi.impetusaisolutions.com/v1",
        "static_token": "",
        ...devapis,
        "open/login": {
            "base": "http://localhost:3000,http://localhost:3001,https://strdapi.impetusaisolutions.com",
            "api_base": "https://strdapi.impetusaisolutions.com/v1",
            ...devapis,
        },
    },
    "staging": {
        "base": "https://strdapi.impetusaisolutions.com",
        "api_base": "https://strdapi.impetusaisolutions.com/v1",
        "static_token": "",
        ...stagingapis,

        "open/login": {
            "base": "https://strdapi.impetusaisolutions.com",
            "api_base": "https://strdapi.impetusaisolutions.com/v1",
            ...stagingapis,
        },
    },
    "production": {
        "base": "https://strdapi.impetusaisolutions.com",
        "api_base": "https://strdapi.impetusaisolutions.com/v1",
        "static_token": "",
        ...stagingapis,

        "open/login": {
            "base": "https://strdapi.impetusaisolutions.com",
            "api_base": "https://strdapi.impetusaisolutions.com/v1",
            ...stagingapis,
        },
    }
}

export default config;
