import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutWrapper from "./LayoutWrapper";
import { LeadsModal } from "./LeadsModal";
import LeadsSearch from "./LeadsSearch";

export class Leads extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Leads");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 50,
        // page: 1,
        // order_by: "updatedAt",
        // order_direction: "DESC",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      schoolAdmins: [],
      openLoader: false,
    };
    this.columns = [
      {
        dataIndex: "cname",
        title: "Name",
        render: (text, record) => {
          return <span className="text-primary">{text || "-"}</span>;
        },
      },
      {
        dataIndex: "fname",
        title: "Fathers Name",
      },
      {
        dataIndex: "mobile",
        title: "Mobile No.",
        // display: false
      },
      {
        dataIndex: "altno",
        title: "Alternate Mobile No.",
      },
      {
        dataIndex: "City",
        title: "City",
      },
      // {
      //   dataIndex: "comment",
      //   title: "Comments",
      //   render: (text, record) => {
      //     return (
      //       <span
      //         className="text_truncate w-200px cursor-pointer"
      //         title={record?.comment}
      //         onClick={() => alert(record?.comment)}
      //       >
      //         {record?.comment}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   dataIndex: "status",
      //   title: "Status",
      //   render: (text) => {
      //     return <>{_enum.LEADS_STATUS_MAP[text]}</>;
      //   },
      // },
      // {
      //   dataIndex: "admin_remarks",
      //   title: "Admin Remarks",
      //   render: (text, record) => {
      //     return (
      //       <span
      //         className="text_truncate w-200px cursor-pointer"
      //         title={record?.admin_remarks}
      //         onClick={() => alert(record?.admin_remarks)}
      //       >
      //         {record?.admin_remarks || "-"}
      //       </span>
      //     );
      //   },
      // },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "name",
        label: "Name",
        required: true,
      },
      {
        type: "email",
        name: "email",
        label: "Email ID",
        required: true,
      },
      {
        type: "text",
        name: "phone_number",
        label: "Mobile No.",
        pattern: "[0-9]{10}",
        onInvalid: (e) =>
          e.target.setError(
            "ex. 91-9XXXXXXXXX 10 digit phone number with country code."
          ),
        onInput: (e) => e.target.setMessage(),
        required: true,
      },
      // {
      //   type: "text",
      //   name: "location",
      //   label: "Location",
      //   required: true,
      // },
      // {
      //   type: "textarea",
      //   name: "comment",
      //   label: "Comments",
      //   controlClass: "!min-h-[100px]",
      //   required: true,
      // },
      // {
      //   type: "textarea",
      //   name: "admin_remarks",
      //   label: "Admin Remarks",
      //   controlClass: "!min-h-[150px]",
      //   required: true,
      // },
      {
        type: "select",
        name: "status",
        label: "Status",
        options: _enum.LEADS_STATUS,
        required: true,
      },
    ];
    this.leadsModalRef = React.createRef();
  }

  componentDidMount() {
    // this.fetchList();
  }

  addModalFields() {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  fetchList() {
    let payload = {
      ...this.state.search,
    };
    console.log({ payload });
    this.setState({
      openLoader: payload?.cname || payload?.mobile ? true : false,
    });

    this.props.api
      .apiGetUsers(payload)
      .then((res) => {
        console.log({ apiGetUsers: res.data.items });
        this.setState({
          openLoader: false,
          data: res.data.items,
          rowcount: res.data.items.length,
          // rowcount: res.data.count,
        });
      })
      .catch((err) => {
        this.setState({
          openLoader: false,
        });
      });
    // payload &&
    //   (payload?.cname || payload?.mobile) &&
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: 50,
        },
      },
      () => this.fetchList()
    );
  }

  // deleteAction(value) {
  //   let payload = {
  //     email: value.email,
  //   };

  //   confirm(`Are you sure want to delete this lead ?`).promise.then(() => {
  //     this.api.apiDeleteLead(payload).then(() => {
  //       this.fetchList();
  //     });
  //   });
  // }

  editAction(v) {
    console.log("edit: ", v);
    if (!v.hasOwnProperty("admin_remarks")) {
      v.admin_remarks = "";
    }
    this.openLeadsModal();
    this.setState({
      popupdata: Object.except(v, ["createdAt", "updatedAt", "admin_remarks"]),
      editMode: true,
      viewMode: false,
      editData: v,
    });
    // update city based on state values
    // let city = this.modalFields.find((o) => o.name == "city");
    // city.options = Object.assign(city.options, statecity[v.state].options());
  }

  viewAction(v) {
    console.log("view: ", v);
    this.openLeadsModal();
    this.setState({
      popupdata: v,
      editMode: false,
      viewMode: true,
      editData: v,
    });
    // let city = this.modalFields.find((o) => o.name == "city");
    // city.options = Object.assign(city.options, statecity[v.state].options());
  }

  onSearch(data) {
    console.log("onSearch data: ", data);
    if (Object.keys(data).length === 0) {
      data = {
        ...this.state.search,
      };
    } else {
      data = {
        ...this.state.search,
        ...data,
      };
    }
    this.setState(
      {
        search: data,
      },
      () => {
        this.fetchList();
      }
    );
  }

  onPopupClose(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      openLoader: false,
    });
  }

  openLeadsModal() {
    this.leadsModalRef.current.click();
  }
  closeModal() {
    document.querySelector("#add_leads_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    console.log("formData", data, form);
    const resolveList = () => {
      success(
        `${
          this.state.editMode
            ? "Leads Updated successfully"
            : "Leads added successfully"
        }`
      );
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    resolveList();
    if (this.state.editMode) {
      // this.props.api
      //   .apiUpdateLead(Object.only(data, ["admin_remarks", "status"]), {
      //     email: this.state.editData.email,
      //   })
      //   .then(resolveList);
    } else {
      let payload = {
        ...data,
      };
      // this.props.api.apiAddSchool(payload).then(resolveList);
    }
  }

  updateFields(fields) {
    // fields.find((o) => o.name === "status").defaultValue = "CONTACTED";
    return fields;
  }

  render() {
    let {
      data = [],
      rowcount,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
    } = this.state;
    let { columns, modalFields } = this;
    modalFields = this.updateFields(modalFields);
    return (
      <LayoutWrapper title="Query" back="">
        <div className="container-fluid outer_container">
          <div className="row mx-auto g-0">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-0">
                  <LeadsSearch
                    data={data}
                    inactive={this.props.inactive}
                    onSearch={(...arg) => this.onSearch(...arg)}
                  />
                  <div className="row Leads">
                    <div className="col-12">
                      {!!data.length && (
                        <AppTable
                          data={data}
                          columns={columns}
                          onNext={() => this.onNext()}
                          onPrev={() => this.onPrev()}
                          onChange={(...arg) => this.onPageChange(...arg)}
                          total={rowcount}
                          reorder={true}
                          // deleteAction={(v) => this.deleteAction(v)}
                          // editAction={(v) => this.editAction(v)}
                          viewAction={(v) => this.viewAction(v)}
                          editable={false}
                          deletable={false}
                          targetType="tap"
                        ></AppTable>
                      )}
                      {data.length == 0 && <div className="empty_layout"></div>}
                    </div>
                  </div>
                  <div className="mb-4">
                    <Link
                      className="btn add-btn hidden"
                      ref={this.leadsModalRef}
                      data-bs-toggle="modal"
                      data-bs-target="#add_leads_modal"
                      onClick={(...args) => this.addModalFields(...args)}
                    >
                      <i className="fa fa-plus"></i>
                      Add Leads
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LeadsModal
          id="add_leads_modal"
          title={`${editMode ? "Edit Query" : "View Query"}`}
          submitText={`${editMode ? "Save" : "Add Leads"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          disablefields={[
            // "name",
            // "email",
            "phone_number",
            // "location",
            // "comment",
          ]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
        <AppPopUp
          openLoader={this.state.openLoader}
          onPopupClose={(...args) => this.onPopupClose(...args)}
          message={"Please wait while we are working on your request"}
        />
      </LayoutWrapper>
    );
  }
}

export default connect(Leads);
