import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class SentSuccessfully extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Change Password - Starpowerz Digital Technologies Pvt. Ltd.</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="account-content">
          <div className="container">
            <div className="account-box">
              <div className="account-wrapper">
                <h4 className="account-title">
                  Email Sent Successfully with temporary Password
                </h4>

                {/* Account Form */}
                <form>
                  <div className="form-group mt-5 d-flex justify-content-center">
                    <button
                      className="btn btn-primary account-btn w-25"
                      type="submit"
                    >
                      Ok
                    </button>
                  </div>
                </form>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(SentSuccessfully);
