import smMapcolumns from "./leads.mapcolumns";

export default {
  apiLeadsDataMoke() {
    let data = [
      {
        name: "Akash Kumar",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "open",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Manohar H",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "converted",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Raghavender",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "not_interested",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Abhishek K",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "interested",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Udith Kumar",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "contacted",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Akash Kumar",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "call_not_answered",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Manohar H",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "call_back",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Raghavender",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "not_interested",
        remarks: "Call Back tomorrow"
      },
      {
        name: "Abhishek K",
        email: "emailid@gmail.com",
        mobile_number: "9878767654",
        location: "Location",
        comments: "Hello I am a java developer",
        status: "interested",
        remarks: "Call Back tomorrow"
      },
    ];
    return this.mokeapi(() => data, "Getting all data please wait...", 1000);
  },

  apiTriggerHeartbeat() {
    return this.request(this.api().heartbeat)
      .exec()
      .mapresults(response => {
        return response
      })
      .log()
      .get();
  },

  apiGetLeads(data, urldata = {}) {
    console.log({ apiUpdateLead: data, urldata });

    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getLeads, data)
      // .urltransform(urldata)
      .exec()
      .mapresults(response => {
        return {
          data: response.data[0].records,
          total: response.data[0].pagination[0]?.totalRecords || 0
        }
      })
      .log()
      .get();
  },

  apiUpdateLead(data, urldata = {}) {
    console.log({ apiUpdateLead: data, urldata });

    urldata = { ...mixins.params(), ...urldata };

    return this.request(this.api().updateLead, data)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiDeleteLead(urldata = mixins.params()) {
    return this.request(this.api().deleteLead)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },

  apiGetUsers(data, urldata = {}) {
    console.log({ apiUpdateLead: data, urldata });
  
    urldata = { ...mixins.params(), ...urldata };
    return this.request(this.api().getUsers, data)
      // .urltransform(urldata)
      .exec()
      .mapresults(response => {
        return {
          data: response.data,
          // total: response.data[0].pagination[0]?.totalRecords || 0
        }
      })
      .log()
      .get();
  },
};
