import React from "react";

const AppPopUp = ({ openLoader, onPopupClose, message }) => {
  return (
    <div>
      {openLoader ? (
        <>
          <div
            className="tailwind AppModal justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[1057] outline-none focus:outline-none"
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <form
              className="relative w-auto my-6 mx-auto max-w-3xl"
              id="appmodal"
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-75 bg-white outline-none focus:outline-none mx-auto">
                <div className="overlay relative p-6 flex-auto">
                  <div className="text-slate-500 text-lg leading-relaxed">
                    <div className="text-end">
                      <span aria-hidden="true">
                        <button
                          className="btn btn-default border-0"
                          style={{ cursor: "pointer", color: "#A9A9A9" }}
                          onClick={onPopupClose}
                        >
                          <i className="fa fa-close"></i>
                        </button>
                      </span>
                    </div>
                    <div>
                      <div className="text-center">
                        <img
                          src={imagepaths.BlueLoaderGif}
                          className="blue_loader"
                          alt="Loader Image"
                        />
                      </div>
                      <div className="text-dark text-center py-4">
                        <b>{message}</b>
                      </div>
                    </div>
                  </div>
                  <div className=" text-center">
                    <button className="btn btn-primary" onClick={onPopupClose}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default connect(AppPopUp);
