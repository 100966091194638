import React, { useEffect, useRef } from "react";

function AppModalBs(props) {
  let {
    title = "Modal Title",
    className = "",
    closeIcon = "fa fa-close",
    contentClass = "",
    onClose = () => "",
  } = props;
  let attrs = Object.except(props, [
    "title",
    "className",
    "role",
    "closeIcon",
    "contentClass",
  ]);
  const closebtn = useRef();
  useEffect(() => {
    const { onLoad = () => "" } = props;
    onLoad({ closebtn });
  }, []);
  return (
    <div
      className={"modal custom-modal fade " + className}
      role="dialog"
      {...attrs}
      data-bs-backdrop="static"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className={"modal-content " + contentClass}>
          <div className="modal-header">
            <h5 className="modal-title m-auto text-3xl font-semibold">
              {title}
            </h5>
            <button
              type="button"
              ref={closebtn}
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="bsmodalclose"
              onClick={onClose}
            >
              <span aria-hidden="true">
                <i className={closeIcon}></i>
              </span>
            </button>
          </div>
          <div className="modal-body">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default AppModalBs;
