/**
 * Signin Firebase
 */

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";

const Loginpage = (props) => {
  const schema2 = yup.object({
    // email: yup
    //   .string()
    //   .matches(window?.constant?.emailrgx, "Email is required")
    //   .required("Email is required")
    //   .trim(),
    password: yup.string().min(6).required("Password is required").trim(),
  });

  const [eye, seteye] = useState(true);
  const [loginToggle, setLoginToggle] = useState(true);
  const [otpToggle, setOtpToggle] = useState(true);
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const {
    control: control2,
    handleSubmit: handlePasswordSubmit,
    setError: setError2,
    clearErrors,
    formState: { errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });

  function onLogin(e) {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      setLoading(false);
      error = true;
    }
    if (email !== "admin@starpowerz.com") {
      errorObj.email = "User not found";
      setLoading(false);
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      setLoading(false);
      error = true;
    }
    if (password !== "aaBB11##") {
      errorObj.password = "Incorrect password";
      setLoading(false);
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    let data = {
      emailAddress: email,
      password: password,
    };
    console.log({ data });

    setTimeout(() => {
      props.setstore("auth", true);
      setLoading(false);
    }, 2000);
  }
  const onPasswordSubmit = (data) => {
    console.log("onPasswordSubmit", data);
    // props.setstore("auth", true);

    // props.api
    //   .apiLogin({
    //     email: data.email,
    //     password: data.password,
    //   })
    //   .then((res) => {
    //     console.log({ res });
    //     props.setstore("logged", res.logged);
    //     props.setstore("auth", res.auth);
    //     props.setstore("user", res.user);

    //     clearErrors("password");
    //   })
    //   .catch(() => {
    //     setError2("password", {
    //       message: "login failed",
    //     });
    //   });
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  const toggleSwitchPassword = () => {
    const passwordField = document.querySelector("#password");
    const togglePassword = document.querySelector("#togglePassword");

    togglePassword.addEventListener("click", function () {
      if (passwordField.type === "password") {
        passwordField.type = "text";
        togglePassword.classList.remove("fa-eye");
        togglePassword.classList.add("fa-eye-slash");
      } else {
        passwordField.type = "password";
        togglePassword.classList.remove("fa-eye-slash");
        togglePassword.classList.add("fa-eye");
      }
    });
  };
  useEffect(() => {
    console.log("run heartbeat");
    props.api.apiTriggerHeartbeat().then((res) => {
      console.log({ apiTriggerHeartbeat: res });
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login - Starpowerz Digital Technologies Pvt. Ltd.</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="account-content">
        {/* <Link to="/applyjob/joblist" className="btn btn-primary apply-btn">
					Apply Job
				</Link> */}
        <div className="container">
          {/* /Account Logo */}
          <div className="account-box">
            <div className="account-wrapper pt-3">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to={"/open/login"}>
                  <img src={imagepaths.Applogo} alt="logo image" />
                  <p className="m-0 tagline_text">
                    Starpowerz Digital Technologies Pvt. Ltd.
                  </p>
                </Link>
              </div>
              <h3 className="account-title">Login</h3>
              {/* Account Form */}
              {/* <form
                className="email_form"
                key={2}
                onSubmit={handlePasswordSubmit(onPasswordSubmit)}
              >
                <>
                  <div className="form-group">
                    <label>Email Address</label>
                    <Controller
                      name="email"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                        <input
                          className={`form-control  ${
                            errors2?.email ? "error-input" : ""
                          }`}
                          type="text"
                          value={value}
                          onChange={onChange}
                          autoComplete="false"
                          placeholder="Enter your email here"
                          required={true}
                        />
                      )}
                    />
                    <small>{errors2?.email?.message}</small>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <label>Password</label>
                      </div>
                    </div>
                    <Controller
                      name="password"
                      control={control2}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input
                            type={eye ? "password" : "text"}
                            className={`form-control  ${
                              errors2?.password ? "error-input" : ""
                            }`}
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                            placeholder="Enter your password here"
                            required={true}
                          />
                          <span
                            onClick={onEyeClick}
                            className={`fa toggle-password" ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                      )}
                    />
                    <small>{errors2?.password?.message}</small>
                    <div className="col-auto d-flex justify-content-end">
                      <Link className="text-muted" to="/open/forgotpassword">
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div className="form-group text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </>
              </form> */}

              <form
                className=" dz-form pb-3"
                // onSubmit={onLogin}
                onSubmit={!isLoading ? onLogin : null}
              >
                <div className="form-group mb-3">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="password-container form-group mb-3 position-relative">
                  <label>Password</label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i
                    class="fa fa-eye"
                    id="togglePassword"
                    onMouseEnter={() => toggleSwitchPassword()}
                  ></i>
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <div className="form-group mb-5 d-flex align-items-start justify-content-end">
                  <span>
                    <NavLink
                      to="/open/forgetpassword"
                      className="ms-auto text-muted"
                    >
                      Forgot Password ?
                    </NavLink>
                  </span>
                </div>
                <div
                  className={Object.className({
                    "form-group text-left": true,
                    "not-allowed": isLoading,
                  })}
                >
                  <button
                    type="submit"
                    className="btn btn-primary account-btn"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading…" : "login"}
                  </button>
                </div>
              </form>
              {/* /Account Form */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(Loginpage);
